import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./page/search-mod/search-mod.module').then( m => m.SearchModPageModule)
    //loadChildren: () => import('./page/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'user-detail/:mid',
    loadChildren: () => import('./page/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'unit-detail/:unit_id/:unit_title',
    loadChildren: () => import('./page/unit-detail/unit-detail.module').then( m => m.UnitDetailPageModule)
  },
  {
    path: 'unit-mod',
    loadChildren: () => import('./page/unit-mod/unit-mod.module').then( m => m.UnitModPageModule)
  },
  
  {
    path: 'favorite-mod',
    loadChildren: () => import('./page/favorite-mod/favorite-mod.module').then( m => m.FavoriteModPageModule)
  },
  {
    path: 'search-mod',
    loadChildren: () => import('./page/search-mod/search-mod.module').then( m => m.SearchModPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then( m => m.LoginPageModule)
  },
  //{
    //path: 'login-mod',
    //loadChildren: () => import('./page/login-mod/login-mod.module').then( m => m.LoginModPageModule)
  //},
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
